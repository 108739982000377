import { lazy } from "react";

export const Home = lazy(() => import("./Home"));
export const SignIn = lazy(() => import("./SignIn"));
export const SignUp = lazy(() => import("./SignUp"));
export const Profile = lazy(() => import("./Profile"));
export const YandexVerify = lazy(
	() => import("src/features/Auth/components/Yandex/YandexVerify"),
);
export const Business = lazy(() => import("./Business"));
